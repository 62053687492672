import {Menu, Tag, Typography} from "antd";
import {downloadDocument} from "app/API/BaseAPI";
import FlexibleTable from "app/Base/Tables/FlexibleTable/FlexibleTable";
import {usePPMailData} from "app/Data/Base";
import React, {useState} from "react";
import {Link} from "react-router-dom";

import {DateTime} from "../../Common/OtherComponents";
import useContragent from "../ContragentContext";
import {ForwardMailModal, MailModal} from "../Modals/MailModal";


export function PPMailTable() {
    const [emailBody, setEmailBody] = useState();
    const {contragent} = useContragent();
    const [ppmail, setPPMail] = useState(null);
    const model = usePPMailData({contragent});

    const columns = [

        {
            title: "Получатели",
            dataIndex: "emails",
            key: "emails",
            render: (value) => value && value.map(em => em).join(", "),

        }, {
            title: "Контрагент", dataIndex: "contragent", key: "contragent", render: (val, rec) => (
                (val)?<Link to={`/contragents/${val?.id}`}>{val?.name}</Link>:"- нет -"
            )
        }, {
            title: "Тема", dataIndex: "subject", key: "subject",
        }, {
            title: "Статус", dataIndex: "status", key: "status", render: (val, obj) => {
                if (val === "new") return <Tag>Новый</Tag>;
                if (val === "success") return <Tag color="green">Отправлено</Tag>;
                if (val === "error") return <Tag color="red">Ошибка при отправке</Tag>;
            },
        }, {
            title: "Отправлено", dataIndex: "_created", key: "_created", render: (value) => {
                return value ? <DateTime dt={value}/> : null;
            },
        }, {
            title: "Текст", dataIndex: "body", key: "body", render: (text) => {
                const maxLength = 100;
                return text && text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
            },
        }, // {
        //     title: "Вложения",
        //     dataIndex: "attachments",
        //     key: "attachments",
        //     render: (value) => value && value.map(att =>
        //                                               <Typography.Link
        //                                                   onClick={() => downloadDocument(att.file_url)}>{att.file_name}</Typography.Link>,
        //     ),
        // },
        {
            title: "Вложения", dataIndex: "attachments", key: "attachments", render: (value) => (<>
                {value && value.map((att, index) => (<div key={index} style={{marginBottom: "8px"}}>
                    <Typography.Link
                        onClick={() => downloadDocument(att.file_url)}
                    >
                        {att.file_name}
                    </Typography.Link>
                </div>))}
            </>),
        }];

    return <>
        <FlexibleTable model={model}
                       columns={columns}
                       actions={(record) => {
                           return <Menu>
                               <Menu.Item key="1">
                                   <Typography
                                       onClick={() => {
                                           setEmailBody(record.body);
                                       }}
                                   >Показать содержимое</Typography>
                               </Menu.Item>
                               <Menu.Item key="2">
                                   <Typography onClick={() => {
                                       setPPMail(record);
                                   }}>
                                       Переслать
                                   </Typography>
                               </Menu.Item>
                           </Menu>;
                       }}
        />
        <MailModal
            visible={!!emailBody}
            onCancel={() => setEmailBody(null)}
            emailBody={emailBody}
        />

        {!!ppmail &&
            <ForwardMailModal
                visible={!!ppmail}
                ppmail={ppmail}
                onSubmit={() => setPPMail(null)}
                onCancel={() => setPPMail(null)}
            />}
    </>;

}
