import React from "react";
import {Button, Modal} from "antd";
import CrossgradeServiceChangeForm, {
    ChangeProlongForm,
    CrossgradeServiceGroupChangeForm,
} from '../Forms/CrossgradeServiceChangeForm';
import { SubscribeService } from '../../../API/ServiceAPI';
import { EditAttributesForm } from '../../Common/Forms/AttributesForm';


export default function SubscribeCrossgradeModal({item, service_item, onSuccess, ...props}) {
// модальное окно по информации кроссгрейдов

    function onSuccessForm() {
        typeof onSuccess === "function" && onSuccess();
    }

    return (
        <Modal {...props} destroyOnClose title="Выбор тарифа" width={800} footer={
            <Button htmlType="submit" form="crossgrade_change_form" type="primary">Подтвердить</Button>
        }>
            <CrossgradeServiceChangeForm
                id="crossgrade_change_form"
                item={item}
                service_item={service_item}
                isAtolSpecial={props.isAtolSpecial} // явный ключ!
                onFinish={onSuccessForm}
            />
        </Modal>
    );
}

export function SubscribeGroupModal({item, service_item, onSuccess, ...props}) {
    // модальное окно по информации кроссгрейдов

    function onSuccessForm() {
        typeof onSuccess === "function" && onSuccess();
    }

    return (
        <Modal {...props} width={800}
               destroyOnClose title="Выбор тарифа" footer={
            <Button htmlType="submit" form="crossgrade_change_form" type="primary">Подтвердить</Button>
        }>
            <CrossgradeServiceGroupChangeForm
                id="crossgrade_change_form"
                item={item}
                service_item={service_item}
                isAtolSpecial={props.isAtolSpecial}
                onFinish={onSuccessForm}
            />
        </Modal>
    );
}

export function SubscribeProlongGroupModal({service, onFinish, ...props}) {
    // модальное окно по информации кроссгрейдов

    const onSuccessForm = () => {
        typeof onFinish === "function" && onFinish();
    };

    return (
        <Modal {...props}
               destroyOnClose
               title="Изменить дату продления"
               footer={
                   <Button htmlType="submit" form="change_group_prolong" type="primary">Подтвердить</Button>
               }>
            <ChangeProlongForm
                id="change_group_prolong"
                service={service}
                onFinish={onSuccessForm}
            />
        </Modal>
    );
}


export function SubscribeAttributesModal({service_item, onFinish, ...props}) {
    // модальное окно для изменения атрибутов

    const onFinishForm = () => {
        typeof onFinish === 'function' && onFinish();
    }

    return (
        <Modal {...props} destroyOnClose title='Редактирование атрибутов' footer={
            <Button htmlType='submit' form='attributes_change_form' type='primary'>Подтвердить</Button>
        }>
            <EditAttributesForm
                id='attributes_change_form'
                obj={service_item}
                contragent={service_item?.contract?.buyer}
                apiService={SubscribeService}
                onFinish={onFinishForm}
                />
        </Modal>
    );
}
