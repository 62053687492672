import React from "react";
import {Layout, Skeleton} from "antd";
import {Content, Header, Sider, Footer} from "./Layout";
import {Route, Switch, Redirect} from "react-router-dom";
import {Client, Module, ProfileSettings, Variable, Rules} from "./index";
import User from "./Users/User";
import Dictionaries from "src/Components/Dictionary/Dictionaries";
import useUser from "../Providers/UserProvider";
// import CtgSelectModal from "./Auth/CtgSelectModal";
// import Product from './Products/Products'
import {OffersTable} from "./Products/Table/OffersTable";
import Template from "./Templates/Templates";
import {Settings} from "./Settings/Settings";
import Package from "./Products/Packages";
import OrdersTable from "./Contragents/Tables/OrdersTable";
import ReportsComponent from "./Reports/Reports";
import {QueryClientProvider, QueryClient} from "react-query";
// import { DiscountForm } from "./Discount/Form/DiscountForm";
import DiscountRouter from "./Discount/Discount";



const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retryOnMount: false,
            enabled: true
        },
    },
});

export default function PriceplanWelcome() {
    // Основной компонент админки
    const {contragent } = useUser();
    // const {atol} = useIntegration();

    // const history = useHistory();

    // useEffect(() => {
    //     if (contragent && history.location.pathname === '/') {
    //         // if (is_global_permitted('access_main_contragent_page')) {
    //         history.push(`/contragents/${contragent.id}`)
    //         // } else {
    //         //     history.push("/financial")
    //         // }
    //     }
    // }, [user, contragent])


    if (!contragent) {
        return <Skeleton />
    }

    return (
        <Layout style={{minHeight: "100vh"}}>
            <Header/>
            <Layout>
                <Sider/>
                <Content>
                    <QueryClientProvider client={queryClient}>
                        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                            <div style={{flex: 1}}>
                                <Switch>
                                    <Route path="/offers">
                                        <OffersTable/>
                                    </Route>
                                    <Route path="/packages">
                                        <Package/>
                                    </Route>
                                    <Route path="/contragents/:contragent_id/discount">
                                        <DiscountRouter />
                                    </Route>
                                    <Route path="/contragents">
                                        <Client/>
                                    </Route>
                                    <Route path="/usermng">
                                        <User/>
                                    </Route>
                                    <Route exact path="/profile">
                                        <ProfileSettings/>
                                    </Route>
                                    <Route path="/settings">
                                        <Settings/>
                                    </Route>
                                    <Route exact path="/variables">
                                        <Variable/>
                                    </Route>
                                    <Route exact path="/dictionary">
                                        <Dictionaries/>
                                    </Route>
                                    <Route path="/modules">
                                        <Module/>
                                    </Route>
                                    <Route exact path="/">
                                        <Redirect to="/contragents"/>
                                    </Route>
                                    <Route path="/templates">
                                        <Template/>
                                    </Route>
                                    <Route path="/rules">
                                        <Rules/>
                                    </Route>
                                    <Route exact path="/order-manage">
                                        <OrdersTable/>
                                    </Route>
                                    <Route path="/reports">
                                        <ReportsComponent/>
                                    </Route>
                                </Switch>
                            </div>
                            <Footer/>
                        </div>
                    </QueryClientProvider>
                </Content>
            </Layout>
        </Layout>
    );
}
