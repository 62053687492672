import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Form, Input, Layout, notification, Select} from "antd";
import {ContragentService} from "app/API/ContragentAPI";
import useUser from "app/Providers/UserProvider";
import {PayOrderService, RefillInvoiceService} from "app/API/DocumentAPI";
import {AccountSelect} from "app/Base/Inputs";
import useContragent from "../ContragentContext";
import ContractSelect from "app/Base/Inputs/ContractSelect";


export default function InvoiceCreateForm({form, ...props}) {
    const [ctgs, setCtgs] = useState([]);
    const [accs, setAccs] = useState([]);
    const [name, setName] = useState("");

    const {contragent} = useUser();

    useEffect(() => {
        const contragentService = new ContragentService(contragent.id);

        contragentService.listContracted().then(r => {
            setCtgs(r.data.data);
            setName(`Платёжное поручение от ${moment().format('YYYY-MM-DD')}`)
        });
        contragentService.accounts({type: ["external", 'internal']}).then(({data: {data}}) => {
            setAccs(data)
        })
    }, []);

    function finish(vals) {
        if (name && vals.contragent) {
            vals.name = name;
            // vals.amount = invoiceSum
            new PayOrderService().create(vals)
                .then(r => {
                    notification.success({
                        message: 'Документ создан успешно',
                        description: `Создан документ ${name}`
                    })
                })
                .catch(e => notification.error({
                    message: 'Ошибка создания документа',
                    description: e
                }))

        }
    }

    return (
    <Form form={form}
          layout={"vertical"}
          onFinish={finish}
          {...props}>
        <Form.Item name={'name'}
                 valuePropName={'name'}
                 label={'Заголовок документа'}>
            <Input type={'text'}
                   placeholder={'Заголовок документа'}
                   value={name}
                   onChange={r => setName(r.target.value)}
            />
        </Form.Item>

        <Form.Item name={'name'}
                 valuePropName={'name'}
                 label={'Сумма пополнения'}>
            <Input type={'amount'}
                   placeholder={'Сумма пополнения'}
              // value={invoiceSum}
              // onChange={r => setInvoiceSum(r.target.value)}
            />
        </Form.Item>
        <Form.Item name={'contragent'}
                 valuePropName={'contragent'}
                 label={'Контрагент'}>
            <Select
              showSearch
              options={ctgs.map((v) => ({label: v.name, value: v.id}))}
              // это не нужно, мы создаём счёт или поручение для покупателя,
              // но оплата должна идти на счёт поставщика
              // onChange={(id) => {
              //     new ContragentService(id).accounts().then(r => {
              //         setAccs(r.data.data)
              //     })
              // }}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }/>
        </Form.Item>
        <Form.Item name={'account'}
                 valuePropName={'account'}
                 label={'Лицевой счет'}>
            <Select
              showSearch
              options={accs.map((v) => ({label: v.number, value: v.id}))}
              // onChange={(id) => {
              //     setSelectedAcc(id)
              // }}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }/>
        </Form.Item>
    </Form>
    )
}


export function InvoiceChooseAccountForm({onFinish, trigger, ...props}) {
    const [accs, setAccs] = useState([]);
    const [accId, setAccId] = useState(null);

    // const {setOperVisible} = useOperMenu();
    const {contragent} = useUser();

    useEffect(() => {
        const contragentService = new ContragentService(contragent.id);
        contragentService.accounts({type: ["external", 'internal']}).then(({data: {data}}) => {
            setAccs(data)
        })
    }, []);

    useEffect(() => {
        if (trigger) {
            onFinish(accId);
        }
    }, [trigger]);

    return (
    <Layout>
        <Select showSearch
              style={{width: '100%'}}
              options={accs.map((v) => ({label: v.number, value: v.id}))}
              onChange={(id) => {
                setAccId(id);
              }}
              filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }/>
    </Layout>
    )
}

export function InvoiceRefillForm({form, ...props}) {
    const [accs, setAccs] = useState([]);
    const [acc, setAcc] = useState({});
    const {contragent, setContragent} = useContragent();
    const required = { required: true, message: 'Обязательное поле.' };

    useEffect(() => {
        const contragentService = new ContragentService(contragent.id);
        contragentService.accounts({type: ['internal']}).then(({data: {data}}) => {
            setAccs(data);
        })
    }, [acc]);

    function finish(vals) {
        vals['contragent'] = contragent.id;
        new RefillInvoiceService(contragent.id).create(vals)
        .then(r => {
            notification.success({
                message: 'Документ создан успешно',
                description: `Создан документ ${r.data.name}`
            });
            typeof props.onCancel === 'function' && props.onCancel();
        })
        .catch(e => notification.error({
            message: 'Ошибка создания документа',
            description: e
        }))
    }

    return (
        <Form form={form} layout={"vertical"} onFinish={finish} {...props}>
            <Form.Item name={'destination'}
                       label={'Назначение платежа'}>
                <Input type={'text'}
                       placeholder={'Назначение платежа'}
                />
            </Form.Item>

            <Form.Item name={'contragent'}
                       valuePropName={'contragent'}
                       //rules={[required]}
                       label={'Контрагент'}>
                <Select
                    defaultValue={contragent.id}
                    disabled
                    options={[contragent].map((v) => ({label: v.name, value: v.id}))}
                />
            </Form.Item>

            <Form.Item name={'account'}
                       valuePropName={'account'}
                       label={'Лицевой счет'}
                       rules={[required]}>
                <AccountSelect
                    initialValues={accs[0]}
                    accounts={accs}
                    onChange={(_, r) => {
                        setAcc({id: r.value, number: r.label})
                    }}
                />
            </Form.Item>

            <Form.Item name={'contract'}
                       valuePropName={'contract'}
                       rules={[required]}
                       label={'Контракт'}>
                <ContractSelect showType
                                contragent={contragent}/>
            </Form.Item>

            <Form.Item name={'amount'}
                       rules={[required]}
                       label={'Сумма пополнения'}>
                <Input
                       placeholder={'Сумма пополнения'}
                />
            </Form.Item>
        </Form>
    )
}
