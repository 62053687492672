import React, {useState} from "react";

import {Menu, notification, Tag} from "antd";

import {BlockingService} from "app/API/BlockingAPI";

import {DateTime} from "../../Common/OtherComponents";
import {BlockingModal} from '../../Common/Modal/BlockingModal';
import useContragent from "../ContragentContext";
import FlexibleTable from "app/Base/Tables/FlexibleTable/FlexibleTable";
import {useBlockingData} from "src/Data/Base";


export function BlockingTable() {
    const [modalShow, setModalShow] = useState(false);
    const [blocking, setBlocking] = useState();
    const {contragent} = useContragent();
    const model = useBlockingData(contragent);
    const {toggleTableUpdate} = model;

    function cancelBlocking(blocking_id) {
        new BlockingService(contragent?.id, blocking_id).cancel().then(res => {
            notification.success({message: `Блокировка отменена`})
        });
    }

    const columns = [
        {
            title: "ID", dataIndex: "id", key: "id"
        },
        {
            title: "Тип", dataIndex: "btype", key: "btype",
        },
        {
            title: "Объект блокировки",
            dataIndex: "target_object_name",
            key: "target_object_name",
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            render: (val, obj) => {
                if (val == 'active')
                    return <Tag color='green'>Активна</Tag>
                if (val == 'inactive')
                    return <Tag color='gray'>Неактивна</Tag>
                if (val == 'postponed')
                    return <Tag color='blue'>Отложена</Tag>
            }
        },
        {
            title: "Родительская блокировка",
            dataIndex: "parent_blocking",
            key: "parent_blocking",
        },
        {
            title: "Связанные КА",
            dataIndex: "contragents",
            key: "contragents",
        },
        {
            title: "Комментарий",
            dataIndex: "comment",
            key: "comment",
        },
        {
            title: "Дата активации",
            dataIndex: "activate_date",
            key: "activate_date",
            render: (value) => {
                return value ? <DateTime dt={value}/> : null
            }
        },
        {
            title: "Дата создания",
            dataIndex: "_created",
            key: "_created",
            render: (value) => {
                return value ? <DateTime dt={value}/> : null
            }
        },
        {
            title: "Дата окончания",
            dataIndex: "cancel_date",
            key: "cancel_date",
            render: (value) => {
                return value ? <DateTime dt={value}/> : null
            }
        },
    ];

    return <>
        <FlexibleTable model={model}
                       columns={columns}
                       actions={(rec) => <Menu>
                           <Menu.Item key='cancel_blocking' onClick={() => cancelBlocking(rec.id)}
                                      disabled={rec.status === 'inactive'}>
                               Отменить блокировку
                           </Menu.Item>
                           <Menu.Item key='cancel_blocking'
                                      onClick={() => {
                                           setModalShow(true)
                                           setBlocking(rec)
                                       }}
                                      disabled={rec.status === 'inactive' || rec.btype.includes('аморозк')}>
                               Редактировать
                           </Menu.Item>
                       </Menu>}/>
        <BlockingModal targetObject={null}
                       visible={modalShow}
                       onCancel={() => setModalShow(false)}
                       blocking={blocking}
                       onSuccess={() => {
                           setModalShow(false);
                           toggleTableUpdate();
                       }}/>
    </>;

}
