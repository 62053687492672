import {Button, Layout, List, Result, Skeleton, Space, Steps, Tag, Typography} from "antd";

import {AtolFullReportService, AtolFullReportTransactionService} from "app/API/AtolAPI";
import {FlexibleTable} from "app/Base/Tables/FlexibleTable/FlexibleTable.js";
import {useReportData, useReportTransactionData} from "app/Data/Base";
import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router";
import {Link, useParams} from "react-router-dom";
import {useToggle} from "react-use";

import {Card} from "../../Base";
import {DateTime} from "../Common/OtherComponents";


const REPORTS = [
    {name: "AtolFullReportFilter", ruTitle: "Полный отчет"},
    {name: "AtolFullReportTransactionFilter", ruTitle: "Транзакционный отчет"},
];

function ReportMainPage() {

    return <Card>
        <Typography.Title level={5}>Отчеты </Typography.Title>
        <List
            dataSource={REPORTS}
            renderItem={(item) => (
                <List.Item>
                    <Typography.Text>
                        <Link to={"reports/" + item.name}>{item.ruTitle}</Link>
                    </Typography.Text>
                </List.Item>
            )}
        />
    </Card>;
}

function Report() {
    let {reportName} = useParams();
    const report = REPORTS.find(r => r.name === reportName);

    return <Card>
        <Typography.Title level={5}>{report.ruTitle}</Typography.Title>

        {reportName === "AtolFullReportFilter" &&
            <ReportTable/>
        }
        {reportName === "AtolFullReportTransactionFilter" &&
            <ReportTransactionTable/>
        }

    </Card>;

}

// export function createReportTableColumns(strColumns, initFieldsData, columnProps) {
//     const tableColumns = createTableColumnsFromQueryColumns(strColumns, initFieldsData)
//     let columns = []
//
//     tableColumns.map(field => {
//         let column = {
//             title: field.title,
//             dataIndex: field.name,
//             key: field.name,
//         }
//         if (field.filter_type === 'date') {
//             column['render'] = (dt) => {
//                 return <DateTime dt={dt} dateOnly={true}/>
//             }
//         }
//
//         switch (field.name) {
//             case 'oi_price':
//             case 'oi_tax':
//             case 'order_total_sum':
//             case 'order_total_tax':
//             case 'acc_balance':
//             case 'acc_debt':
//             case 'acc_available':
//             case 'subscribe_price':
//             case 'invoice_amount':
//             case 'invoice_tax':
//             case 'revenue_amount':
//             case 'revenue_tax':
//             case 'tr_amount':
//             case 'inv_amount':
//             case 'acc_to_acc_balance':
//             case 'acc_from_credit_limit':
//             case 'acc_to_acc_debt':
//             case 'acc_to_acc_blocked':
//             case 'acc_to_credit_limit':
//                 column['render'] = (sum) => {
//                     return <Money sum={sum}/>
//                 }
//                 column['align'] = 'right'
//                 break;
//
//             case 'ctg_blocked':
//                 column['render'] = (ctg_blocked) => {
//                     if (ctg_blocked) {
//                         return <Tag color='red'>Да</Tag>
//                     }
//                     return <Tag color='green'>Нет</Tag>
//                 }
//                 break;
//             case 'ctg_name':
//                 column['render'] = (value, record) => {
//                     if (record.ctg_id) {
//                         return <Link to={`/contragents/${record.ctg_id}`}>{value}</Link>
//                     }
//                     return value
//                 }
//                 break;
//             case 'order_status':
//                 column['render'] = (val) => {
//                     return val && OrderStatusTag(val)
//                 }
//                 break;
//             case 'order_type':
//                 column['render'] = (val) => {
//                     return val && OrderTypeTag(val)
//                 }
//                 break;
//             case 'contract_status':
//                 column['render'] = (val) => {
//                     return val && ContractStatusTag(parseInt(val))
//                 }
//                 break;
//             case 'contract_type':
//                 column['render'] = (val) => {
//                     return val && field.choices.filter(c => c.id === parseInt(val))[0].name
//                 }
//                 break;
//             case 'subscribe_status':
//                 column['render'] = (val) => {
//                     return val && CrossgradeStatusTag(parseInt(val))
//                 }
//                 break;
//
//             case 'invoice_status':
//                 column['render'] = (val) => {
//                     return val && OrderPayStatusTag(val)
//                 }
//                 break;
//
//             case 'doc_type':
//                 column['render'] = (val) => {
//                     return val && field.choices.filter(c => c[0] === val)[0][1]
//                 }
//                 break;
//
//             case 'ctg_tariff_type':
//             case 'ctg_client_type':
//                 column['render'] = (val) => {
//                     return val && val.join(', ')
//                 }
//                 break;
//             default:
//                 break;
//         }
//
//         columns.push(column)
//     })
//     return columns
//
// }

// TODO: переместить модели. Был очень срочный функционал

function statusLoad(status) {
    return {
        "SUCCESS": <Tag color="green">Успешно</Tag>,
        "FAILURE": <Tag color="red">Ошибка</Tag>,
        "PENDING": <Tag color="yellow">Не выгружен</Tag>,
        "STARTED": <Tag color="blue">Выгрузка</Tag>,
    }[status];
}

function ReportTable() {

    const [status, setStatus] = useState(null);
    const [dateDone, setDateDone] = useState(null);
    const [update, setUpdate] = useState(false);
    const [meta, setMeta] = useState(null);
    const [exporting, setExporting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tgl, trg] = useToggle();
    const model = useReportData();

    // const timer = useRef();

    useEffect(() => {
        setLoading(true);
        new AtolFullReportService().status().then(res => {
            setStatus(res.data.status);
            setDateDone(res.data.date_done);
            setMeta(res.data.meta);
            switch (res.data.status) {
                case "STARTED":
                    setTimeout(trg, 1000);
                    setExporting(true);
                    break;
                case "PENDING":
                    // setLoading(!!x?.running)
                    setLoading(false);
                    break;
                case "SUCCESS":
                case "REVOKED":
                case "REJECTED":
                case "FAILURE":
                    setExporting(false);
                    setLoading(false);
                    setStatus(res.data.status);
                    localStorage.removeItem("main_report");
                    break;
                default:
                    setLoading(false);
                    setExporting(false);
                    localStorage.removeItem("main_report");
                    break;
            }
        });
    }, [update, tgl]);


    function onPullReport() {
        new AtolFullReportService().run().then(res => {
            setStatus(res.data.msg);
            localStorage.setItem("main_report", new Date().toString());
            trg();
        });
    }

    if (loading) {
        if (exporting) {
            let started = localStorage.getItem("main_report");
            started = new Date(started);
            let now = new Date();
            return <Result
                title={`Отчет в процессе выгрузки`}
                subTitle={`${Math.round((now - started) / 1000)} сек`}
                extra={
                  <Steps current={meta?.step || 0} size='small' direction='vertical'>
                    <Steps.Step title='Запуск' />
                    <Steps.Step title={<Space><Typography>Общий сбор</Typography>
                       {meta?.stage === 'common' && 
                        <Typography.Text type='secondary'>{meta?.total}</Typography.Text>
                       }</Space>}
                    />
                    <Steps.Step title={<Space>
                        <Typography>Контрагенты</Typography>
                       {meta?.stage === 'contragents' && 
                            <Typography.Text type='secondary'>{meta?.current}/{meta?.total}</Typography.Text>
                       }
                    </Space>}
                    />
                    <Steps.Step title={<Space>
                        <Typography>Заказы</Typography>
                        {meta?.stage === 'orders' && 
                            <Typography.Text type='secondary'>{meta?.current}/{meta?.total}</Typography.Text>
                        }
                    </Space>}
                    />
                    <Steps.Step title={<Space>
                        <Typography>Лицевые счета</Typography>
                        {meta?.stage === 'accounts' && 
                            <Typography.Text type='secondary'>{meta?.current}/{meta?.total}</Typography.Text>
                        }
                    </Space>}
                    />
                    <Steps.Step title={<Space>
                            <Typography>Предложения</Typography>
                            {meta?.stage === 'offers' && 
                                <Typography.Text type='secondary'>{meta?.current}/{meta?.total}</Typography.Text>
                            }
                            
                        </Space>
                        }
                    />
                    <Steps.Step title={<Space>
                        <Typography>Подписки</Typography>
                       {meta?.stage === 'subscribes' && 
                        <Typography.Text type='secondary'>{meta?.current}/{meta?.total}</Typography.Text>
                       }
                    </Space>}
                    />
                    <Steps.Step title={<Space>
                        <Typography>Контракты</Typography>
                       {meta?.stage === 'contracts' && 
                        <Typography.Text type='secondary'>{meta?.current}/{meta?.total}</Typography.Text>
                       }
                    </Space>}
                    />
                    <Steps.Step title={<Space><Typography>Документы</Typography>
                       {meta?.stage === 'docs' && 
                        <Typography.Text type='secondary'>{meta?.current}/{meta?.total}</Typography.Text>
                       }
                    </Space>}
                    />
                  </Steps>
                }
            />;
        }
        return <Skeleton></Skeleton>;
    }

    return <>
        <FlexibleTable model={model}
                       actions={false}
                       extra={
                           <>
                               <Typography.Text>{statusLoad(status)}</Typography.Text>
                               <Button onClick={onPullReport}>Сбор данных</Button>
                               <Typography.Text>
                                   <DateTime dt={dateDone}/>
                               </Typography.Text>
                           </>
                       }
        />
    </>;
}

function ReportTransactionTable() {

    const [status, setStatus] = useState(null);
    const [update, setUpdate] = useState(false);
    const [dateDone, setDateDone] = useState(null);
    const [meta, setMeta] = useState(null);
    const [loading, setLoading] = useState(true);
    const [exporting, setExporting] = useState(false);
    const [tgl, trg] = useToggle();
    const model = useReportTransactionData();

    useEffect(() => {
        setLoading(true);
        new AtolFullReportTransactionService().status().then(res => {
            setStatus(res.data.status);
            setDateDone(res.data.date_done);
            setMeta(res.data.meta);
            switch (res.data.status) {
                case "STARTED":
                    setTimeout(trg, 1000);
                    setExporting(true);
                    break;
                case "PENDING":
                    // setLoading(!!x?.running)
                    setLoading(false);
                    break;
                case "SUCCESS":
                case "REVOKED":
                case "REJECTED":
                case "FAILURE":
                    setExporting(false);
                    setLoading(false);
                    setStatus(res.data.status);
                    localStorage.removeItem("report_transaction");
                    break;
                default:
                    setLoading(false);
                    setExporting(false);
                    localStorage.removeItem("report_transaction");
                    break;
            }
        });
    }, [update, tgl]);

    function onPullReport() {
        new AtolFullReportTransactionService().run().then(res => {
            setStatus(res.data.msg);
            localStorage.setItem("report_transaction", new Date().toString());
            trg();
        });
    }

    if (loading) {
        if (exporting) {
            let started = localStorage.getItem("report_transaction");
            started = new Date(started);
            let now = new Date();
            return <Result
                title={`Отчет в процессе выгрузки`}
                subTitle={`${Math.round((now - started) / 1000)} сек`}
                extra={
                  <Steps current={meta?.step || 0} size='small' direction='vertical'>
                    <Steps.Step title='Запуск' />
                    <Steps.Step title={<Space><Typography>Общий сбор</Typography>
                       {meta?.stage === 'common' && 
                        <Typography.Text type='secondary'>{meta?.total}</Typography.Text>
                       }</Space>}
                    />
                    <Steps.Step title={<Space>
                        <Typography>Контрагенты</Typography>
                       {meta?.stage === 'contragents' && 
                            <Typography.Text type='secondary'>{meta?.current}/{meta?.total}</Typography.Text>
                       }
                    </Space>}
                    />
                    <Steps.Step title={<Space>
                            <Typography>Предложения</Typography>
                            {meta?.stage === 'offers' && 
                                <Typography.Text type='secondary'>{meta?.current}/{meta?.total}</Typography.Text>
                            }
                            
                        </Space>
                        }
                    />
                    <Steps.Step title={<Space>
                        <Typography>Лицевые счета</Typography>
                        {meta?.stage === 'accounts' && 
                            <Typography.Text type='secondary'>{meta?.current}/{meta?.total}</Typography.Text>
                        }
                    </Space>}
                    />
                  </Steps>
                }
            />;
        }
        return <Skeleton></Skeleton>;
    }


    return <FlexibleTable model={model}
                          actions={false}
                          extra={
                              <>
                                  <Typography.Text>{statusLoad(status)}</Typography.Text>
                                  <Button onClick={onPullReport}>Сбор данных</Button>
                                  <Typography.Text>
                                      <DateTime dt={dateDone}/>
                                  </Typography.Text>
                              </>
                          }/>;
    // return <>
    //     <FilterTable
    //         modelName={'AtolFullReportTransactionFilter'}
    //         createTableColumns={createReportTableColumns}
    //         QueryFields={CommonQueryFields}
    //         APIService={AtolFullReportTransactionService}
    //         onUpdate={() => setUpdate(p => !p)}
    //         quickSearch={true}
    //         extra={
    //             <>
    //                 <Typography.Text>{StatusLoad(status)}</Typography.Text>
    //                 <Button onClick={onPullReport}>Сбор данных</Button>
    //             </>
    //         }
    //     />
    // </FilterTable>
}

export default function Reports() {

    return <Layout.Content>
        <Switch>
            <Route exact path="/reports">
                <ReportMainPage/>
            </Route>
            <Route path="/reports/:reportName">
                <Report/>
            </Route>
        </Switch>
    </Layout.Content>;
}
